import { useRef, useState } from "react";
import { motion } from "framer-motion";
import chinTapak from "./chintapak.mp4";
function App() {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const handleClick = () => {
    setIsVisible(true);
    videoRef.current?.play();
  };
  return (
    <main className="h-screen bg-black text-white flex justify-center items-center flex-col text-center">
      <video
        ref={videoRef}
        className={`${isVisible ? "block" : "hidden"} w-1/2 h-1/2`}
        controls
      >
        <source src={chinTapak} />
      </video>
      <motion.button
        initial={{ x: "-100%", opacity: 0 }}
        animate={{
          x: 0,
          opacity: [1, 0, 1],
          transition: {
            opacity: { repeat: Infinity, repeatType: "loop", duration: 2 },
          },
        }}
        onClick={handleClick}
        className={`${
          isVisible ? "hidden" : "block"
        }  border border-slate-400  p-2 rounded`}
      >
        Check out my journey
      </motion.button>
    </main>
  );
}

export default App;
